import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { getPostImagePath, getCategoryPath } from '../util';

export default ({ data }) => {
  const edges = data.allMarkdownRemark.edges;
  const firstBlogNode = edges[0] && edges[0].node;
  const restBlogNodes = edges.slice(1).map(edge => edge.node);


  return (
    <Layout>
      <SEO title="Blog" />
  
      <div>
        <div className="section">
          <div className="container">
            <div className="section-title section-title-wide no-bottom-space">
              <h6 className="subheading text-primary-1">Our Blog</h6>
              <h1 className="no-bottom-space">We're here to help</h1>
            </div>
          </div>
        </div>


        {firstBlogNode && (
          <div className="section no-bottom-space bg-gray-4">
          <div className="container">
            <div className="w-dyn-list">
              <div className="w-dyn-items">
                <div className="w-dyn-item">
                  <div className="w-layout-grid featured-blog-post-grid">
                    <div style={{backgroundColor: 'hsla(216.9230769230769, 100.00%, 10.20%, 1.00)'}} className="blog-post-category-placeholder w-condition-invisible"><img src="/fonts/5dc99dd0c36766a50e5938c3_goal.svg" alt="" className="icon-extra-large" /></div><Link to={firstBlogNode.fields.slug} className="w-inline-block">{getPostImagePath(firstBlogNode) && <Img fluid={getPostImagePath(firstBlogNode)} alt="" />}</Link>
                    <div className="featured-blog-post-content"><Link to={firstBlogNode.fields.slug} className="text-gray-1 w-inline-block">
                        <h3 className="h2">{firstBlogNode.frontmatter.title}</h3>
                      </Link>
                      <div className="row row-justify-between text-small">
                          <div className="post-meta-left"><Link to={getCategoryPath(firstBlogNode.frontmatter.category)}>{firstBlogNode.frontmatter.category}</Link>
                          <div className="text-space-left text-muted">{firstBlogNode.frontmatter.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


        <div className="section bg-gray-4">
          <div className="container">
            <div className="w-dyn-list">
              <div className="grid-thirds blog-grid-thirds w-dyn-items">
                {restBlogNodes.map((node) => {
                  const { id, fields: { slug }, frontmatter: { title, category, author, date } } = node;

                  return (
                    <div key={id} className="w-dyn-item">
                      <div key={id} className="card no-border blog-card"><Link to={slug} className="w-inline-block">{getPostImagePath(node) && <Img fluid={getPostImagePath(node)} alt="" className="card-image-head" />}</Link>
                        <div className="card-body blog-card-body"><Link to={slug} className="card-blog-link w-inline-block">
                          <h4>{title}</h4>
                          </Link></div>
                        <div className="card-foot no-border text-small">
                          <div className="row row-justify-between">
                            <div className="post-meta-left"><Link to={getCategoryPath(category)}>{category}</Link>
                              <div className="text-space-left text-muted">{date}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1000) {
      edges {
        node {
          id
          frontmatter {
            title,
            category,
            author,
            date(formatString: "MMMM Do, YYYY"),
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
